import { React, useEffect, useState } from "react";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet } from "../../Methods/Save";
import Title from "../Title";
import { Helmet } from "react-helmet";
function Content({
  setMetaDesc,
  setMetaKw,
  setOgtitle,
  setOgDesc,
  setOgSiteName,
  setTwitterCard,
  setTwitterTitle,
  setTwitterSite,
  setTwitterDesc,
  setMetaTitle,
  modal,
}) {
  const [pageDetails, setPageDetails] = useState([]);

  useEffect(() => {
    var url = APIURL() + "term-details";
    axiosGet(url).then((response) => {
      setPageDetails(response.data[0].response.data[0]);
      setMetaDesc(response.data[0].response.data[0].meta_desc);
      setMetaKw(response.data[0].response.data[0].meta_keyword);
      setOgtitle(response.data[0].response.data[0].og_title);
      setOgDesc(response.data[0].response.data[0].og_desc);
      setOgSiteName(response.data[0].response.data[0].og_site_name);
      setTwitterCard(response.data[0].response.data[0].twitter_card);
      setTwitterDesc(response.data[0].response.data[0].twitter_desc);
      setTwitterSite(response.data[0].response.data[0].twitter_site);
      setTwitterTitle(response.data[0].response.data[0].twitter_title);
      setMetaTitle(response.data[0].response.data[0].meta_tag);
    });
  }, []);
  return (
    <>
      <div>
        {/* <Title title= {pageDetails.page_title} /> */}
          <Title title={pageDetails.page_title} />
        <Helmet>
          <meta name="title" content={pageDetails.meta_tag} />
          <meta name="description" content={pageDetails.meta_desc} />
          <meta name="keywords" content={pageDetails.meta_keyword} />

          <meta property="og:title" content={pageDetails.og_title} />
          <meta property="og:description" content={pageDetails.og_desc} />

          {/* Twitter Card Meta Tags (if needed) */}
          <meta name="twitter:card" content={pageDetails.twitter_card} />
          <meta name="twitter:title" content={pageDetails.twitter_title} />
          <meta name="twitter:description" content={pageDetails.twitter_desc} />
        </Helmet>
        <section className={modal ? "d-none" : "article-header"}>
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-xl-8 wow fadeInRightBig mx-auto"
                data-wow-delay="0.2s"
              >
                <div className="inner-title-small">
                  <h3>{pageDetails.title}</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy-box-sec">
          <div className="container">
            <div
              className="terms-condition"
              dangerouslySetInnerHTML={{ __html: pageDetails.description }}
            ></div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Content;
