import React, { useEffect, useState } from "react";
import logo from "../images/logo.png";
import bluelogo from "../images/blue-logo.png";
import { Link } from "react-router-dom";
import profile from "../images/profile.png";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ShopInnerPageHeader from "./ShopInnerPageHeader";

function InnerPageHeader(props) {
  const { activeTab, bussiness_type, signedIn, SetSignedIn, step, page } =
    props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const logout = () => {
    setAnchorEl(null);
    localStorage.removeItem("logged_in");
    localStorage.removeItem("user_id");
    SetSignedIn(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [userDetails, setUserDetails] = useState();
  useEffect(() => {
    if (localStorage.getItem("user_details") !== null) {
      setUserDetails(JSON.parse(localStorage.getItem("user_details")));
    }
  }, [localStorage.getItem("user_details")]);
  return (
    <>
      <header
        className={
          bussiness_type == "true"
            ? "checkout-sec-header buisness-mobile2"
            : "checkout-sec-header"
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="head-bar">
                <div className="row align-items-center">
                  <div className="col-xl-2 col-md-2">
                    <div className="check-out-logo">
                      <Link to={bussiness_type == "true" ? "/business" : "/"}>
                        <img src={bussiness_type == "true" ? bluelogo : logo} />
                      </Link>
                    </div>
                  </div>

                  {page == "shop" || page == "mobile" ? (
                    <div className="col-xl-7 col-md-7 desktop_responsive">
                      <ShopInnerPageHeader step={step} />
                    </div>
                  ) : (
                    <div className="col-xl-7 col-md-7 desktop_responsive">
                      {activeTab != "address" ? (
                        <div className="menu">
                          <nav id="cssmenu" className="head_btm_menu">
                            <ul>
                              <li
                                className={
                                  activeTab == "priceSpeed" ? "active" : ""
                                }
                              >
                                <a href="#">Speed</a>
                              </li>
                              <li
                                className={
                                  activeTab == "contract" ? "active" : ""
                                }
                              >
                                <a href="#">Contract</a>
                              </li>
                              {/* <li
                                className={
                                  activeTab == "goLive" ? "active" : ""
                                }
                              >
                                <a href="#">Go Live</a>
                              </li> */}
                              <li
                                className={
                                  activeTab == "payment" ? "active" : ""
                                }
                              >
                                <a href="#">Payment</a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  {signedIn == true ? (
                    <div className="col-xl-3 col-md-3">
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <div className="innerpage_header_uma">
                          <img src={profile} alt="prf" />
                          <span>
                            Hi,{" "}
                            {userDetails
                              ? `${userDetails.first_name} ${userDetails.last_name}`
                              : ""}
                            <KeyboardArrowDownIcon />
                          </span>
                        </div>
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={handleClose}>
                          <Link className="" to={"/my-account"}>
                            {" "}
                            My Account
                          </Link>
                        </MenuItem>
                        <MenuItem onClick={logout}>Logout</MenuItem>
                      </Menu>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default InnerPageHeader;
