import { React, useState, useEffect, Suspense, lazy } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
// import HomepageSlider from "../Components/Homepage/HomepageSlider";
// import Reviewsection from "../Components/Homepage/Reviewsection";
import CallUs from "../Components/Homepage/CallUs";
import CustomerService from "../Components/Homepage/CustomerService";
// import LookBeforeChoosing from "../Components/Homepage/LookBeforeChoosing";
import QuestionAnswer from "../Components/Homepage/QuestionAnswer";
import DontSlowDown from "../Components/Homepage/DontSlowDown";
import StillNotSure from "../Components/Homepage/StillNotSure";
import { axiosGet } from "../Methods/Save";
import { APIURL } from "../Methods/Fetch";
import Title from "../Components/Title";
import LocalStorage from "../Components/Homepage/LocalStorage";
// import CookieHandler from "../Components/Homepage/Cookiehandler";
import { Helmet } from "react-helmet";
const HomepageSlider = lazy(() =>
  import("../Components/Homepage/HomepageSlider")
);
const Reviewsection = lazy(() =>
  import("../Components/Homepage/Reviewsection")
);
const LookBeforeChoosing = lazy(() =>
  import("../Components/Homepage/LookBeforeChoosing")
);

function Homepage(props) {
  const {
    setMetaDesc,
    setMetaKw,
    setOgtitle,
    setOgDesc,
    setOgSiteName,
    setTwitterCard,
    setTwitterTitle,
    setTwitterSite,
    setTwitterDesc,
    setMetaTitle,
  } = props;
  const [pageDetails, setPageDetails] = useState([]);
  const [correctPass, setCorrectPass] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    var url = APIURL() + "home-page-details";
    axiosGet(url).then((response) => {
      setPageDetails(response.data[0].response.data[0]);
      setMetaDesc(response.data[0].response.data[0].meta_desc);
      setMetaKw(response.data[0].response.data[0].meta_keyword);
      setOgtitle(response.data[0].response.data[0].og_title);
      setOgDesc(response.data[0].response.data[0].og_desc);
      setOgSiteName(response.data[0].response.data[0].og_site_name);
      setTwitterCard(response.data[0].response.data[0].twitter_card);
      setTwitterDesc(response.data[0].response.data[0].twitter_desc);
      setTwitterSite(response.data[0].response.data[0].twitter_site);
      setTwitterTitle(response.data[0].response.data[0].twitter_title);
      setMetaTitle(response.data[0].response.data[0].meta_tag);
    });
  }, []);
  const [phone, setPhone] = useState();

  const [modal, setModal] = useState(true);
  const toggle = () => {
    setModal(!modal);
  };
  const proceed = () => {
    if (password == "password") setCorrectPass(true);
    else alert("wrong password");
  };

  // if (!correctPass) {
  //   // setModal(true);
  //   return (
  //     <div>
  //       <Modal isOpen={modal} toggle={toggle}>
  //         <ModalHeader toggle={toggle}>Site is protected</ModalHeader>
  //         <ModalBody>
  //           <div className="d-flex">
  //             <p>Password : </p>
  //             <Box
  //               component="form-control"
  //               sx={{
  //                 "& > :not(style)": { m: 1, width: "25ch" },
  //               }}
  //               noValidate
  //               autoComplete="off"
  //             >
  //               <TextField
  //                 id="outlined-basic"
  //                 label="Password"
  //                 variant="outlined"
  //                 type="password"
  //                 value={password}
  //                 onChange={(e) => setPassword(e.target.value)}
  //               />
  //             </Box>
  //           </div>
  //         </ModalBody>
  //         <ModalFooter>
  //           <Button color="primary" onClick={proceed}>
  //             Proceed
  //           </Button>{" "}
  //         </ModalFooter>
  //       </Modal>
  //     </div>
  //   );
  // } else
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LocalStorage />
      {/* <CookieHandler /> */}
      {/* <Title title={pageDetails.page_title} /> */}
        <Title title={pageDetails.page_title} />
      <Helmet>
        <meta name="title" content={pageDetails.meta_tag} />
        <meta name="description" content={pageDetails.meta_desc} />
        <meta name="keywords" content={pageDetails.meta_keyword} />

        <meta property="og:title" content={pageDetails.og_title} />
        <meta property="og:description" content={pageDetails.og_desc} />

        {/* Twitter Card Meta Tags (if needed) */}
        <meta name="twitter:card" content={pageDetails.twitter_card} />
        <meta name="twitter:title" content={pageDetails.twitter_title} />
        <meta name="twitter:description" content={pageDetails.twitter_desc} />
      </Helmet>
      <Header />
      <HomepageSlider />
      <div>
        <CallUs phone={phone} />
        <CustomerService
          title={pageDetails.service_title}
          desc={pageDetails.service_desc}
        />
        <LookBeforeChoosing
          title={pageDetails.choose_title}
          desc={pageDetails.choose_desc}
        />
        {/* skill bar */}
        {/* <div class="bubu">
		<h2>GeeksforGeeks</h2>
	</div> */}
        <Reviewsection
          title={pageDetails.testimonial_title}
          desc={pageDetails.testimonial_desc}
        />
        <StillNotSure
          title={pageDetails.contact_title}
          desc={pageDetails.contact_desc}
        />
        <QuestionAnswer
          desc={pageDetails.faq_desc}
          faq_btn_name={pageDetails.faq_btn_name}
          faq_btn_link={pageDetails.faq_btn_link}
        />
        <DontSlowDown
          image={pageDetails.slowdown_img}
          desc={pageDetails.slowdown_desc}
          slow_down_btn={pageDetails.slow_down_btn}
        />
      </div>
      <Footer setPhone={setPhone} />
    </Suspense>
  );
}

export default Homepage;
